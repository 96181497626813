import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div style={{ maxWidth: `85px`, marginBottom: `1.5rem`, margin: 'auto' }}>
      <Image />
    </div>
    <SEO title="Payroll and benefits for small businesses" />
    <h1>The lights are on...stay tuned.</h1>
    <p>We're hard at work building a better payroll 
      and benefits solution for small businesses. Provide your email below, and 
      we'll let you know when we launch. It will not be shared with 
      anyone else or used for any other purpose.</p>
    <form 
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field">
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
    <input name="email" placeholder="Enter your email" type="email"></input>
    <button className="primary" type="submit">Submit</button>
    </form>
  </Layout>
)

export default IndexPage
